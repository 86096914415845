.week-picker-display {
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  transition: 0.5s ease;
  font-size: 1rem;
  @apply text-base-300;
  @apply flex;
  /* @apply min-w-56; */
  @apply justify-end;
  @apply items-center;
  @apply py-2;

  @apply select-none;
}

.week-picker-options {
  padding: 0.4rem;
  width: 300px;
  position: absolute;
  top: 105%;
  /* left: 0;
  transform: translateX(-25%); */
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  z-index: 99;
  flex-direction: column;
  align-items: center;

  @apply border;
  @apply border-solid;
  @apply border-base-150;
  @apply bg-base-100;
}

.title-week {
  padding: 0.8rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.numbers-container {
  width: 100%;
  padding: 0.3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.single-number {
  width: 100%;
  aspect-ratio: 1;
  font-size: 0.8rem;
  background: none;
  display: grid;
  place-items: center;
  @apply text-base-300;
}

.selected-week {
  @apply text-base-100;
  @apply bg-primary;
}

.other-month {
  background: none;
  @apply text-base-200;
}

.day {
  background: none;
  @apply text-base-300;
}

.arrow-container {
  cursor: pointer;
  width: 25px;
  height: 25px;
  transition: 0.2s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.arrow-container svg {
  @apply stroke-base-300;
}

.arrow-container:hover {
  @apply bg-primary;
  border-radius: 15%;
}

.arrow-container:hover svg {
  @apply stroke-base-100;
  @apply fill-base-100;
}
